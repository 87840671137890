@tailwind base;
@tailwind components;
@tailwind utilities;

.active{
    @apply border-cyan-400
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    z-index: -1;
  }
  
/* Track */
::-webkit-scrollbar-track {
border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #d8d8d8;
opacity: 10px;
border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb:hover{
background:  #c9c9c9;
opacity: 10px;
border-radius: 10px;
}


.rec.rec-arrow {
  background-color:rgb(53, 57, 57);
  
  color: aqua;
  
  }
  
  .rec.rec-arrow:hover {
  background-color:#3b3e3e;
  color: aqua;
  bottom: aqua;
  
 
  
  }
  
  .rec.rec-arrow:disabled {
    visibility: hidden;
    
    
  }

  .rec-carousel-item:focus {
  outline:none;
  box-shadow: inset 0 0 1px 1px lightgrey;
  background-color: #eaeaea;
  
  }

  